<template>
  <div>
    <div class="hero-section">
      <div class="container">
        <div class="row">
          <div class="col-md-4 custom_animation text-center text-md-start">
            <img
              src="../../assets/image/1737127245559710.jpg"
              alt="დავით კენჭაძე"
              class="profile-img mb-4"
            />
          </div>
          <div class="col-md-8 custom_animation text-center text-md-start">
            <div class="border-bottom border-2 border-black mb-3">
              <h1 class="mb-4 title">გახადე ცხოვრება უკეთესი</h1>
              <p class="lead mb-4">
                რადგან შენ იმსახურებ მეტს - კარგ სამსახურს, ხარისხიან
                განათლებას, ფინანსურ თავისუფლებას და უკეთეს სახლს, სადაც გექნება
                ყველაფერი, რაც ასე ძალიან გინდა.
              </p>
            </div>
            <h5 class="text-center text-md-end title">დავით კენჭაძე</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-5">
      <div class="grid-container">
        <div class="row g-2">
          <div
            v-for="(item, index) in cards"
            :key="index"
            class="col-sm-6 col-xl-4"
          >
            <a target="_blank" :href="item.link">
              <div
                class="card shadow-sm custom_animation"
                :class="`card-${item.number}`"
              >
                <div class="card-number">
                  <span v-if="item.number < 10">0</span>{{ item.number }}
                </div>

                <!-- linketree -->
                <div v-if="item.type === 'group'">
                  <img
                    src="../../assets/icons/linketree_dark.png"
                    alt="linketree"
                    class="w-25 mb-3"
                  />
                  <img
                    src="../../assets/icons/linketree_white.png"
                    alt="linketree"
                    class="w-25 mb-3"
                  />
                </div>
                <!-- /linketree -->

                <h3 class="card-title">{{ item.name }}</h3>
                <p v-if="item.type !== 'group'" class="card-text">
                  {{ item.description }}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "Welcome_2",
  data() {
    return {
      cards: [
        {
          type: "jobs",
          name: "KENCHADZE.Jobs",
          description: "უბრალოდ კარგი ვაკანსიები",
          link: "https://v.dk.ge/Home",
          number: 1,
          color: "#246bf6",
        },
        {
          type: "academy",
          name: "KENCHADZE.Academy",
          description: "გახდი შენი თავის საუკეთესო ვერსია",
          link: "https://academy.dk.ge/",
          number: 2,
          color: "#4b4caf",
        },
        {
          type: "network",
          name: "KENCHADZE.Network",
          description: "შენი კარიერული განვითარების აგენტი",
          link: "https://network.dk.ge/GoldList",
          number: 3,
          color: "#003472",
        },
        {
          type: "real_estate",
          name: "KENCHADZE.Real Estate",
          description: "როცა ეძებ უძრავ ქონებას",
          link: "https://re.dk.ge/",
          number: 4,
          color: "#080066",
        },
        {
          type: "invetments",
          name: "KENCHADZE.Investments",
          description: "აღმოაჩინე ჩვენი მიმდინარე მშენებლობები",
          link: "https://in.dk.ge/Investments",
          number: 5,
          color: "#0026ad",
        },
        {
          type: "developers",
          name: "KENCHADZE.Developers",
          description: "როცა არჩევ სამშენებლო კომპანიას",
          link: "https://developers.dk.ge/",
          number: 6,
          color: "#004736",
        },
        {
          type: "rent",
          name: "KENCHADZE.Rent",
          description: "როცა უძრავი ქონების დაქირავება გინდა",
          link: "https://rent.dk.ge/",
          number: 7,
          color: "#006a7d",
        },
        {
          type: "podcats",
          name: "KENCHADZE.Podcast",
          description: "საუბრები ყველაფერზე, რაც ჩვენს ცხოვრებას აუმჯობესებს",
          link: "https://open.spotify.com/show/0K5csz9pQ4atb3MvBPjzKX?si=0257768dc8a140a1",
          number: 8,
          color: "#210f42",
        },

        {
          type: "dkcapital",
          name: "DK CAPITAL",
          description: "როცა ყიდი უძრავ ქონებას",
          link: "https://dkcapital.ge/",
          number: 9,
          color: "#ffb800",
        },
        {
          type: "group",
          name: "KENCHADZE.Group",
          description: "linketree",
          link: "https://linktr.ee/davidkenchadze",
          number: 10,
          color: "#000000",
        },
      ],
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    const elements = document.querySelectorAll(".custom_animation");
    ScrollTrigger.batch(elements, {
      start: "top bottom-=10",
      onEnter: (batch) => {
        gsap.to(batch, {
          opacity: 1,
          y: 0,
          stagger: 0.15,
          duration: 0.1,
          ease: "power2.out",
        });
      },
      onLeave: (batch) => {
        gsap.to(batch, {
          opacity: 0,
          y: -50,
          stagger: 0.15,
          duration: 0.1,
          ease: "power2.in",
        });
      },
      onEnterBack: (batch) => {
        gsap.to(batch, {
          opacity: 1,
          y: 0,
          stagger: -0.15,
          duration: 0.1,
          ease: "power2.out",
        });
      },
      onLeaveBack: (batch) => {
        gsap.to(batch, {
          opacity: 0,
          y: 50,
          stagger: -0.15,
          duration: 0.1,
          ease: "power2.in",
        });
      },
    });
    // საწყისი სტილები
    gsap.set(elements, {
      opacity: 0,
      y: 50,
    });

    const currentUrl = window.location.href.toLowerCase();
    const baseUrls = [
      "https://v.dk.ge",
      "https://v.dk.ge/",
      "http://v.dk.ge",
      "http://v.dk.ge/",
    ];

    if (baseUrls.includes(currentUrl)) {
      window.location.href = "https://v.dk.ge/Home";
    }
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--bold-font);
  font-feature-settings: "case" on;
}

a {
  text-decoration: none;
}

.hero-section {
  padding: 4rem 0;
  background-color: #f8f9fa;
  margin-bottom: 4rem;
}

.card {
  height: 100%;
  transition: all 0.3s ease !important;
  border: 1px solid #eee;
  border-radius: 1rem;
  padding: 4rem 2rem 4rem 2rem;
  background-color: rgb(249, 249, 249);
  color: #333;
  font-family: var(--Third-Font);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem 2rem 6rem 2rem;
}

.card-1:hover {
  background-color: var(--color-1);
}

.card-2:hover {
  background-color: var(--color-2);
}

.card-3:hover {
  background-color: var(--color-3);
}

.card-4:hover {
  background-color: var(--color-4);
}

.card-5:hover {
  background-color: var(--color-5);
}

.card-6:hover {
  background-color: var(--color-6);
}

.card-7:hover {
  background-color: var(--color-7);
}

.card-8:hover {
  background-color: var(--color-8);
}

.card-9:hover {
  background-color: var(--color-9);
}

.card-10:hover {
  background-color: var(--color-10);
}

.profile-img {
  width: 200px;
  height: 200px;
  border-radius: 1rem;
  object-fit: cover;
}

.lead {
  font-family: var(--Third-Font);
}

.grid-container {
  margin: 2rem 0;
}

.card-number {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: inherit;
  transition: color 0.3s ease;
  font-family: var(--bold-font);
}

.card-1 .card-number {
  color: var(--color-1);
}

.card-2 .card-number {
  color: var(--color-2);
}

.card-3 .card-number {
  color: var(--color-3);
}

.card-4 .card-number {
  color: var(--color-4);
}

.card-5 .card-number {
  color: var(--color-5);
}

.card-6 .card-number {
  color: var(--color-6);
}

.card-7 .card-number {
  color: var(--color-7);
}

.card-8 .card-number {
  color: var(--color-8);
}

/* და ა.შ. */

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: inherit;
  transition: color 0.3s ease;
  font-family: var(--bold-font);
}

.card-text {
  font-size: 0.9rem;
  opacity: 0.9;
  color: inherit;
  transition: color 0.3s ease;
}

.custom_animation {
  opacity: 0;
  transform: translateY(50px);
}

.card:hover * {
  color: white;
  transition: color 0.3s ease;
}

.card-10 img:nth-of-type(2) {
  display: none;
}

.card-10:hover img:nth-of-type(1) {
  display: none;
}

.card-10:hover img:nth-of-type(2) {
  display: block;
}

@media screen and (max-width: 767px) {
  .hero-section {
    padding: 1.5rem 0;
    margin-bottom: 0rem;
  }

  .card {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }

  .card:hover {
    transform: translateY(-5px);
    padding: 2rem 1.5rem 2rem 1.5rem;
  }

  .profile-img {
    width: 150px;
    height: 150px;
  }

  .lead {
    font-size: 0.9rem;
  }

  .title {
    font-size: 1.2rem !important;
  }
}
</style>
